import React, { useState } from 'react';
import '../../assets/css/signIn.scss';
import '../../assets/css/registration/accountVerification.scss';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import logoTns from '../../assets/img/logo_tns_black.svg';
import logoMarquee from '../../assets/img/logo_marquee_black.svg';
import { CustomToast } from '../Shared/withToast';

import RegistrationsAPI from '../../api/Registration';

function ContactVerification() {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    code: '',
    processing: false,
    linkToken: urlParams.get('link_token'),
  });

  const { confirmVerification } = RegistrationsAPI();

  const handleCodeChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, code: value }));
  };

  const sendConfirmVerification = (token) => {
    const { code } = state;
    setState((prevState) => ({ ...prevState, processing: true }));

    confirmVerification(code, token)
      .then((response) => {
        console.log('response', response);
        if (response.data) {
          toast(<CustomToast type="success" text={response.data.message} />);
          history.push('/');
        }
      })
      .catch((error) => {
        if (get(error, 'response.data.errors')) {
          toast(<CustomToast type="error" text={get(error, 'response.data.errors[0].detail')} />);
        } else {
          toast(<CustomToast type="error" text="Could not send message!" />);
        }
        setState((prevState) => ({ ...prevState, processing: false }));
        if (get(error, 'response.data.redirect')) {
          history.push('/');
        }
      });
  };

  return (
    <div className="sign-in-page-container">
      <div className="sign-in-page">
        <div className="logos my-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="TNS Logo" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="Marquee Logo" />
          </div>
        </div>
        <div className="flex self-center md:w-1/2 sm:px-0 w-full">
          <div className="flex flex-col px-5 w-full items-center">
            <div className="tab-container">
              <div className="tab ovf-none">
                <div className="account-verification-title">
                  <span className="account-verification-title-text">Activation</span>
                </div>
                <span className="account-verification-info pb-6">
                  Insert the code you received on your cellphone to verify your account
                </span>
                <span className="phone-number-text label pb-3">Verification Code</span>
                <div className="pb-3 w-100 relative">
                  <input
                    id="verification_code"
                    className="input-primary verification-code-input w-full"
                    placeholder="Enter your verification code"
                    type="number"
                    value={state.code}
                    onChange={handleCodeChange}
                  />
                </div>
                <span className="account-verification-info">
                  Please check your WhatsApp/SMS for the code.
                </span>
              </div>
              <div className="flex mt-5 justify-center">
                <button
                  type="button"
                  onClick={() => sendConfirmVerification(state.linkToken)}
                  className={
                    !state.processing
                      ? 'button-sign-in flex justify-center md:w-1/2 sm:py-10 w-full'
                      : 'button-sign-in-grey flex justify-center md:w-1/2 sm:py-10 w-full'
                  }
                >
                  {state.processing ? 'Processing...' : 'CONFIRM'}
                </button>
              </div>
            </div>

            <div className="sign-in-page-footer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactVerification;
