import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IntlTelInput from 'react-intl-tel-input';
import phoneIcon from '../../../assets/icons/phone.svg';
import { cellPhoneForPayload } from '../../../assets/js/constants';

function InputCellPhone({ handleCellPhoneChange }) {
  const [state, setState] = useState({
    cellPhone: '',
    cellPhoneValid: false,
    cellPhoneCountry: {},
  });

  const updateCellPhone = (numberState, number, countryData) => {
    let finalNumber = '';
    const numbers = number.split('');
    const lastNumberIntroduced = numbers[numbers.length - 1];

    // removes any non-numeric characters
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(lastNumberIntroduced)) {
      finalNumber = number;
    } else {
      numbers.pop();
      finalNumber = numbers.join('');
    }

    handleCellPhoneChange({
      cellPhone: cellPhoneForPayload(cellPhoneCountry, finalNumber),
      cellPhoneValid: numberState,
    });

    setState((prevState) => ({
      ...prevState,
      cellPhone: finalNumber,
      cellPhoneValid: numberState,
      cellPhoneCountry: countryData,
    }));
  };

  const {
    cellPhone,
    cellPhoneValid,
    cellPhoneCountry,
  } = state;

  return (
    <>
      <IntlTelInput
        fieldId="cellPhone"
        onPhoneNumberChange={(value, number, countryData) => updateCellPhone(value, number, countryData)}
        defaultCountry="ae"
        containerClassName="intl-tel-input"
        inputClassName={cellPhoneValid ? 'input-signin-cell-phone' : 'input-signin-cell-phone-error'}
        value={cellPhone}

      />
      <img className="phone-icon" src={phoneIcon} alt="" />
    </>
  );
}
InputCellPhone.propTypes = {
  handleCellPhoneChange: PropTypes.func.isRequired,
};

export default InputCellPhone;
