/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ReactComponent as BackIcon } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as InviteFriendIcon } from '../../assets/icons/invite.svg';
import { ReactComponent as TnsLogo } from '../../assets/img/logo_tns.svg';

import '../../assets/css/shared.scss';
import '../../assets/css/referrals/inviteFriend.scss';

import { CustomToast } from '../Shared/withToast';
import withUserRetriever from '../Shared/withUserRetriever';

import RegistrationsAPI from '../../api/Registration';
import InputCellPhone from '../Shared/Registration/InputCellPhone';

const InviteShare = ({ shareLogo, shareType, handleReferral }) => (
  <div
    role="button"
    tabIndex={0}
    className="invite-friend-share-choices-item"
    onClick={() => handleReferral(shareType)}
  >
    <img src={shareLogo} className="invite-friend-share-choices-item-icon" alt={shareType} />
    <span className="invite-friend-share-choices-item-text my-2">{shareType}</span>
  </div>
);

function InviteFriend() {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const [state, setState] = useState({
    cellPhone: '',
    processing: false,
  });

  const {
    cellPhone,
    processing,
  } = state;

  const history = useHistory();

  const componentStyle = {
    color: level.level_color,
    colorDark: `var(--color-level${level.id}-dark)`,
  };

  const { sendReferralInvite } = RegistrationsAPI();

  const sendVerificationMessage = (regType) => {
    setState((prevState) => ({ ...prevState, processing: true }));

    sendReferralInvite(cellPhone)
      .then((response) => {
        if (response.data.redirect) {
          const toastInfoText = `It looks like your friend already registered with us!
            Please enter a different one.`;
          toast(<CustomToast type="info" text={toastInfoText} />, { autoClose: 10000 });
          history.push('/');
        } else {
          toast(<CustomToast type="success" text="Your friend is now invited!" />);
          history.push('/verification-info');
        }
      })
      .catch((error) => {
        if (get(error, 'response.data.errors')) {
          toast(<CustomToast type="error" text={get(error, 'response.data.errors[0].detail')} />);
        } else {
          toast(<CustomToast type="error" text="Could not send message!" />);
        }
        setState((prevState) => ({ ...prevState, processing: false }));
      });
  };

  const updateCellPhone = ({ cellPhone: cellPhoneData }) => {
    setState((prevState) => ({ ...prevState, cellPhone: cellPhoneData }));
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="invite-friend-container view-boundaries-top" style={{ background: componentStyle.color }}>
        <div className="invite-friend-top">
          <div className="invite-friend-top-header">
            <div role="button" tabIndex={0} onClick={() => history.push('/dashboard')}>
              <BackIcon className="invite-friend-top-back-icon" stroke={componentStyle.color} />
            </div>
          </div>
        </div>
        <div className="invite-friend-bottom">
          <TnsLogo
            className="invite-friend-location-logo"
            style={{ background: componentStyle.color, borderColor: componentStyle.color }}
            fill="#FFFFFF"
          />
          <div className="invite-friend-bottom-content p-6">
            <div className="invite-friend-bottom-title mb-4 mt-6">
              One for you, One for them
            </div>
            <div className="invite-friend-bottom-text my-2">
              Refer a friend to TNS and you&apos;ll
              receive a voucher for 100 AED when they make their first visit.
              They&apos;ll also get one too!
            </div>

            <div className="tab-container">
              <div className="tab ovf-none">

                <span className="account-verification-info pb-6">
                  Please enter your friend&apos;s phone number below and we will send the invite.
                </span>
                <span className="phone-number-text label pb-3">Phone number</span>
                <div className="pb-3 w-100 relative">
                  <InputCellPhone handleCellPhoneChange={updateCellPhone} />
                </div>
                <span className="account-verification-info">
                  Please use a Whatsapp enabled phone number.
                </span>
              </div>
              <button
                type="button"
                className={`invite-friend-button w-full mt-6 ${!processing ? '' : ' disabled'}`}
                onClick={!processing ? sendVerificationMessage : null}
              >
                <InviteFriendIcon
                  className="invite-friend-button-icon"
                  stroke={componentStyle.color}
                  fill={componentStyle.color}
                />
                Invite a friend
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InviteShare.propTypes = {
  shareType: PropTypes.string.isRequired,
  shareLogo: PropTypes.string.isRequired,
  handleReferral: PropTypes.func.isRequired,
};

export default withUserRetriever(InviteFriend);
