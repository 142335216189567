import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomToast } from '../Shared/withToast';
import RegistrationsAPI from '../../api/Registration';
import Loading from '../Loading';

function VerifyShortUrl() {
  const { code } = useParams();
  const history = useHistory();
  const { verifyCode } = RegistrationsAPI();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const errorMessage = `Could not find associated link with this code: ${code}`;
    async function validateUrl() {
      setProcessing(true); // Start loading
      try {
        const response = await verifyCode(code);

        if (response.data && response.data.valid) {
          const url = response.data.redirectUrl;
          window.location.replace(url);
        } else {
          toast(<CustomToast type="error" text={`${errorMessage}`} />);
          history.push('/');
        }
      } catch (err) {
        const detail = err?.response?.data?.errors?.[0]?.detail ?? errorMessage;
        toast(<CustomToast type="error" text={`${detail}`} />);
        history.push('/');
      } finally {
        setProcessing(false); // End loading
      }
    }

    if (code) validateUrl();
  }, []);

  if (processing) {
    return (
      <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full h-full">
        <Loading backgroundColor="white" />
      </div>
    );
  }

  return null;
}

export default VerifyShortUrl;
